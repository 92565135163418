<script>
   import { createEventDispatcher } from 'svelte'
   export let slide;
   export let mode;
   export let size
   export let bid
   export let mobileMode
   export let isMobile


   const dispatch = createEventDispatcher()
   if(mode=="overlay"){
    mode = "overlay_custom"
   }

  let isSendFlag= false
  const domain = window.location.hostname

  function sendRenderStat(){

    if (slide.isVisible && !isSendFlag) {
      let viewed =   window.localStorage.getItem(bid)

      if(viewed && viewed.length){

        viewed = viewed.split(',')
        viewed.push(slide.id)
        viewed = viewed.join(',');
        window.localStorage.setItem(bid, viewed)

      }else{

        window.localStorage.setItem(bid, slide.id)
      }

    fetch(`https://setka.media/external/teaser-view?tid=${slide.id}&bid=${bid}`)
    isSendFlag = true

   }
   }
$: slide.isVisible, sendRenderStat()


function sendClickStat(){


  fetch(`https://setka.media/external/teaser-click?tid=${slide.id}&bid=${bid}`).then(()=>{
    dispatch('changeSlide', {
      id:slide.id
    })
  })



}


let  styleImage
let styleLink
const flexString = `
        position:absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width:100%;
        height:100%;
        `


// function init(){
 $: if(!isMobile){

      if(mode==='overlay_top'){
      styleImage = `width: 100%; height: ${size.slideHeight}px;`
      }
      else if(mode==='overlay_flex'){
        styleLink = `width: 100%; padding-top:75%;`
        styleImage = flexString
    }
    else if (mode ==="classic_flex"){
      styleLink = `width: 100%;`
      styleImage = flexString
    }
    else{
      styleImage = `width: ${size.slideWidth}px; height: ${size.slideHeight}px;`
      styleLink = `width: ${size.slideWidth}px;`
    }
  }else{
    console.log('change', isMobile);
    if(mobileMode){
      if(mobileMode==='flex' || mobileMode==="2x3"){
        styleImage = flexString
        styleLink = 'width:100%'
      }
      else if(mode==='overlay_flex'){
        // styleLink = 'width:100%; padding-top:50%'
        styleLink = 'width:100%;'
        styleImage = flexString
      }
    }else{
      styleImage = `width: ${size.slideWidth}px; height: ${size.slideHeight}px;`
      styleLink = `width: ${size.slideWidth}px;`
    }

  }
// }




</script>




<a class="slide" style={styleLink}  href={`${slide.url}?bid=${bid}&utm_source=${domain}`}  on:click={sendClickStat} target="_black">
  {#if isMobile && (mobileMode==='flex' || mobileMode==="2x3" || mobileMode==="1x3" || mobileMode==="1x3_top")}
    <div class="slide__wrapper">
      <img class="slide__image" style={styleImage} src={slide.image} alt={slide.text} loading="lazy">
    </div>
  {:else}
      {#if mode==="classic_flex"}
        <div class="slide__wrapper_desc">
          <img class="slide__image" style={`${styleImage}border-radius: 10px 10px 0px 0px;`} src={slide.image} alt={slide.text} loading="lazy">
        </div>
      {:else}
        <img class="slide__image" style={styleImage} src={slide.image} alt={slide.text} loading="lazy">
      {/if}
  {/if}

  {#if mode!=='long'}
    <span class ={mode}>
      {slide.text}
    </span>
  {/if}
</a>



<style>
.slide{
  width: 100%;
  display: block;
  border-radius: 10px;
  position: relative;
  transition: .3s;
  overflow: hidden;
  box-sizing: border-box;
  text-decoration: none;
}
.slide__wrapper,.slide__wrapper_desc {
  width: 100%;
   padding-top:50%;
  position: relative;
}
.slide__wrapper_desc{
  padding-top:75%;
}
.slide__image{
  object-fit: cover;
  border-radius: 10px;
}

.overlay_custom, .overlay_top, .overlay_flex{
  font-size: 14px;
  line-height: 1.4rem;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0;
  text-align: left;
  font-weight: bold;
  color: white;
  padding: 60px 10px 15px;
  background: linear-gradient(0deg, #000, rgba(0, 0, 0, 0));
}

.overlay_top{
  top: 0px;
  padding: 15px;
  text-align: left;
  left: 0;
}

.classic, .classic_flex{
  font-size: 16px;
  line-height: 1.2rem;
  box-sizing: border-box;
  left: 0;
  display: block;
  width: 100%;
  position: inherit;
  color: #333;
  background: none;
  text-align: start;
  line-height: 19.4px;
  height: 75px;
  overflow: hidden;
  text-align: center;
  padding-top: 0px;
}

.long{
  width: 100%;
}

</style>

<script>
	export let tiserList;
	export let spareTeasers;
	export let type;
	export let mode;
	export let bid;
	export let slideWidth;
	export let slideHeight
	export let mobileMode = 'stripe'
	export let signature_show;
	import ClassicSlider from "./ClassicSlider.svelte"
	import Matrix from "./Matrix.svelte"
	import { onMount } from 'svelte';
	
	let isMatrix = false
	let isSlider = false

		onMount(() => {

			isMatrix = type.indexOf('matrix') !== -1
			isSlider = type.indexOf('slider') !== -1
		});
	
</script>

<div class="main-setka"> 
	{#if isSlider===true}	
		<ClassicSlider signature_show={signature_show} type={type} mode={mode} spareTeasers={spareTeasers} tiserList={tiserList} slideWidth={slideWidth} slideHeight={slideHeight} bid={bid}></ClassicSlider>	
	{/if}

	{#if isMatrix===true}		
		<Matrix spareTeasers={spareTeasers} mobileMode={mobileMode} bid={bid} mode={mode} tiserList={tiserList} type={type} slideWidth={slideWidth} slideHeight={slideHeight}></Matrix>
	{/if}
	
</div>

<style>

	*{
		box-sizing: border-box;
	}
	.main-setka {
		font-size: 16px;
		line-height: 1.2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		width: 100%;
		margin: 0 auto;
		font-family: Verdana, sans-serif;
	}


</style>
<script>
export let data;
export let bid
export let spareTeasers;
export let mode;


let isSendFlag = false;
const domain = window.location.hostname
function sendClickStat(){  
  fetch(`https://setka.media/external/teaser-click?tid=${data.id}&bid=${bid}`).then(()=>{
    if(spareTeasers.length){
      data = spareTeasers.pop();
      isSendFlag = false;
      data.isVisible= true;
    }
  })
}

function sendRenderStat(){        
    if (data.isVisible && !isSendFlag) {   

      let viewed =   window.localStorage.getItem(bid)
      if(viewed && viewed.length){
        viewed = viewed.split(',')
        viewed.push(data.id)
        viewed = viewed.join(',');
        window.localStorage.setItem(bid, viewed)    
      }else{
        window.localStorage.setItem(bid, data.id)    
      }
    fetch(`https://setka.media/external/teaser-view?tid=${data.id}&bid=${bid}`)
    isSendFlag = true
   }
   }   
$: data.isVisible, sendRenderStat()

</script> 

<a class="{mode != 'no-image' ? "stripe" : "stripe stripe--no-image"}" href={`${data.url}?bid=${bid}&utm_source=${domain}`}  on:click={sendClickStat} target="_black">
  {#if mode!="no-image"}
    <img class="stripe__image"  src={data.image} alt={data.text} loading="lazy"/>
  {/if}
  <div class="stripe__text custon__text">{data.text}</div>
</a>


<style>

  .stripe{
    display: grid;
    gap:1rem;
    grid-template-columns: 70px 1fr;
    color: black;
    text-decoration: none;
  }
  .stripe--no-image{
    grid-template-columns: 1fr;
    gap:.5rem;
  }

  .stripe__image{
    width:100%;
    border-radius: 10px;
  }

  .stripe__text{
    text-align: left;
  }
</style>
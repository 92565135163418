import App from './App.svelte';
import getTeasersInterface from './helpers/API/getTeasersInterface';
import getTeasersTypeInterface from './helpers/API/getTeasersTyteInterface';



function Setka(options) {




  const url = `https://setka.media/rotator`

  //  const url = `https://setka.media/go-rotator`
  const viewed = localStorage.getItem(options.bid) ?? []

  getTeasersInterface(url, { viewed, bid: options.bid })
    .then(async({ teasers, bench, count =10 }) => {
      if (!teasers.length) return;
      teasers = teasers.filter(item => item.text !== undefined)
      let spareTeasers = bench
      teasers.forEach(element => {
        element.isVisible = false
      })

      const selectorsList = document.querySelectorAll(options.blockid)
      console.log(options.bid );
      const numberOfInstans = selectorsList.length;

      while (teasers.length < count * numberOfInstans ) {
        const temp = [...teasers]
        teasers = [...teasers, ...temp]
      }




      selectorsList.forEach((selector, index) => {
        selector.innerHTML = ''
        const teaserList = teasers.slice(index * count, index * count + count)
        
        const props = {
          signature_show: options.params.signature_show ?? true,
          type: options.params.type,
          mode: options.params.mod,
          mobileMode: options.params.mobileMode,
          tiserList: teaserList,
          spareTeasers: spareTeasers,
          bid: options.bid,
          slideWidth: options.params.slideWidth ?? 300,
          slideHeight: options.params.slideHeight ?? 300
        }
        new App({
          target: selector,
          props
        });
      })
    }).catch(error => {
      console.error("Setka is broken")
    })


}


export default Setka;


import Setka from "./main";
import checkCanTeaserStart from './helpers/checkCanTeaserStart.js'
import deleteDublicate from "./helpers/deleteDublicate";
import getTeasersTypeInterface from "./helpers/API/getTeasersTyteInterface";
function CreateSetkaMedia() {
    let canStart = checkCanTeaserStart()
    if (!canStart) return
    const setkaList = document.querySelectorAll(".js-setka-media")
    if (setkaList.length) {
        const clearBidList = deleteDublicate(setkaList)
        clearBidList.forEach(async (bid) => {

            const params = await (await getTeasersTypeInterface('https://setka.media/external/rotator-view?bid=' + bid))

            Setka({
                bid: bid,
                blockid: `.js-setka-media[data-bid="${bid}"]`,
                params
            });
        })


    }



}
CreateSetkaMedia()

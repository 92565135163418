<script>
import { onMount } from "svelte";

  export let type
  export let tiserList;
  export let mode;
  export let slideWidth;
  export let slideHeight;
  export let bid;
  export let mobileMode ;
  export let spareTeasers;

  import ClassicSlide from "./ClassicSlide.svelte"
  import HeaderComponent from "./HeaderComponent.svelte";
  import checkVisible from "./helpers/checkVisible"
  import Stripe from "./Stripe.svelte";

  let isVisible = false
  let matrix;
  let isMobile  = false        
  let isSendStat = false
  let minWidth = 900; // 4x2 matrix_5x2
  
  if (type === "matrix_2x2" || type === "matrix_1x3") {
    minWidth = 500
  }
  if (type ==="matrix_3x1" || type === "matrix_3x2") {
    minWidth = 700
  }
  if (type === "matrix_4x1" || type === "matrix_4x2") {
    minWidth = 800
  }
  
  
    onMount (function init() {
      isVisible = checkVisible(matrix)        
      isMobile = matrix.parentElement.clientWidth <= minWidth         
      if (isMobile && mobileMode === 'stripe') {
        type = 'matrix_1x3_stripe'
      }
      if (isMobile && mobileMode === 'flex') {
        type = 'matrix_1x3'
        mode = 'overlay_flex'
      }
      if (isMobile && mobileMode === '2x3') {
        type = 'matrix_2x2'
        mode = 'classic'        
      }
      if (isMobile && mobileMode === '1x3') {
        type = "matrix_1x3"
      }
      if (isMobile && mobileMode === '1x3_top') {
        type = "matrix_1x3_top"    
        mobileMode = 'flex'    
      }
    })


    function scrollVertical() {
      if (checkVisible(matrix) && isVisible !==true) {
        isVisible = true
      }
    }

    function changeVisibleSlide() {
      if (isVisible) {
        if (!isSendStat) {
         fetch(`https://setka.media/external/block-view?bid=${bid}`)			
         isSendStat = true;
        }
        tiserList.forEach(element => {
          element.isVisible = true
        });
        tiserList = tiserList
      }
    }  

    $: isVisible, changeVisibleSlide()

</script>



<svelte:window on:scroll={scrollVertical}/>
<div class={'matrix'} bind:this={matrix}>
    <!-- <HeaderComponent/> -->
    <div class={"matrix__list " + type}>
      {#each tiserList as slide,index}
          {#if type=="matrix_1x3_top"}
            {#if  index==0}
            <ClassicSlide spareTeasers={spareTeasers} mobileMode={mobileMode} isMobile={isMobile} bid={bid}  mode={mode} size={{slideWidth, slideHeight}} slide={slide}></ClassicSlide>
            {:else}
              <Stripe spareTeasers={spareTeasers} data={slide} bid={bid}></Stripe>          
            {/if}
        {:else if type=="matrix_1x3_stripe" && mode!=="no-image"}
          <Stripe spareTeasers={spareTeasers} data={slide} bid={bid}></Stripe>  
        {:else if mode=="no-image"}
           <Stripe spareTeasers={spareTeasers} data={slide} bid={bid} mode={mode}></Stripe>     
        {:else}         
          <ClassicSlide spareTeasers={spareTeasers} mobileMode={mobileMode} isMobile={isMobile} bid={bid} mode={mode} size={{slideWidth, slideHeight}} slide={slide}></ClassicSlide>
        {/if}
      {/each}
      
    </div>
</div>

<style>
  .matrix{
    width: 100%;
  }

  .matrix__list{
    display: grid;
    overflow:auto;
    gap: 1rem;    
    width: 100%;
  }


  .matrix_3x1{
    grid-template-columns: 1fr 1fr 1fr;
  }
  .matrix_4x1{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .matrix_5x1{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .matrix_3x2{   
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .matrix_4x2{   
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .matrix_5x2{   
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .matrix_2x2{   
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .matrix_1x3,.matrix_1x3_top {
    grid-template-columns: 1fr;
  }
</style>


export default function checkCanTeaserStart(){
    let canStart = false
    if (window.setkaIsInitTime) {
      console.log(new Date().getTime() - window.setkaIsInitTime ); 
      canStart =  new Date().getTime() - window.setkaIsInitTime > 3 * 1000
    } else { 
      window.setkaIsInitTime = new Date().getTime()
      canStart= true
    }
    return canStart
  }
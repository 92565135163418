export default async function getTeasersInterface(url, variavles) {
    const { viewed, bid } = variavles
    return fetch(url, {
        method: "post",
        body: JSON.stringify({ bid, viewed: viewed.length > 0 ? viewed.split(',') : [] }),
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    }).then((data)=>{           
        return data.json()
      })
}
// export default function visibleCheking(target){
//     const parent = target.offsetParent
//     // Все позиции элемента
//     const targetPosition = {
//         top: parent.offsetTop + target.getBoundingClientRect().top,
//         left: parent.offsetLeft ,
//         right:  parent.offsetLeft + target.getBoundingClientRect().right,
//         bottom: parent.offsetTop + target.getBoundingClientRect().bottom
//       },
//       // Получаем позиции окна
//       windowPosition = {
//         top:parent.offsetTop,
//         left:  parent.offsetLeft,
//         right:  parent.offsetLeft + parent.clientWidth,
//         bottom: parent.offsetLeft + parent.clientHeight
//       };
//     return targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
//       targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
//       targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
//       targetPosition.left < windowPosition.right;
// }
export default function visibleCheking(target){
  // Все позиции элемента
  const targetPosition = {
      top: window.pageYOffset + target.getBoundingClientRect().top,
      left: window.pageXOffset + target.getBoundingClientRect().left,
      right: window.pageXOffset + target.getBoundingClientRect().right,
      bottom: window.pageYOffset + target.getBoundingClientRect().bottom
    },
    // Получаем позиции окна
    windowPosition = {
      top: window.pageYOffset,
      left: window.pageXOffset,
      right: window.pageXOffset + document.documentElement.clientWidth,
      bottom: window.pageYOffset + document.documentElement.clientHeight
    };

  return targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
    targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
    targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
    targetPosition.left < windowPosition.right;
}

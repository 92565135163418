<script>
import { onDestroy, onMount } from "svelte";


 export let tiserList;
 export let mode;
 export let slideWidth
 export let slideHeight
 export let bid;
 export let type;
 export let spareTeasers;
 export let signature_show;

import ClassicSlide from "./ClassicSlide.svelte";
import HeaderComponent from "./HeaderComponent.svelte";
import checkVisible from "./helpers/checkVisible"


  let slider;
  let isLeftSide = true;
  let isRightSide = false;
  let windowWidth
  let ostatok
  let count
  let isSendStat = false
  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)
  
  let isVisible = false
  // let itsAlive = false
  let timer = null

  const checkScroll  = function(){
    const newCount = (slider.scrollLeft + ostatok) / (slideWidth + 16)
    const totalCount = Math.trunc( count + newCount)
    // console.log(totalCount)
    for (let index = count -1; index < totalCount; index++) {
      tiserList[index].isVisible = true
    }

    // Проверки границ
    // console.log("right side asd",slider.scrollLeft, slider.scrollWidth,slider.clientWidth )
      if (slider.scrollLeft === 0) {        
        isLeftSide = true   
      } else if (slider.scrollWidth - slider.scrollLeft - 5 <= slider.clientWidth) { //5 пикселей это костыль на мобильных появляется погрешность
        console.log("right side")
        isLeftSide = false
        isRightSide = true

        //Достигли правой стороны значит все тизеры просмотремы
        for (let index = count -1; index < tiserList.length; index++) {
            tiserList[index].isVisible = true
        }

      } else {
        isLeftSide = false
        isRightSide = false
      }
    }

  const scrollRight = ()=>{    
    slider.scrollBy(slideWidth, 0)
  }

  const scrollLeft = ()=>{   
    slider.scrollBy(-slideWidth, 0)
  }


  onMount(()=>{

    //Шлем данные что юзер шевелится
    // timer = setInterval(()=>{
    //   if(itsAlive) {
    //     fetch('https://setka.media/magic-url', {
    //       method: "POST"          
    //     })
    //   }
    //   itsAlive = false;
    // }, 10 * 1000)



    isVisible = checkVisible(slider)  
    windowWidth = slider.offsetWidth 
    // console.log(windowWidth , tiserList.length * slideWidth);       

    if(windowWidth > tiserList.length * slideWidth){
      isRightSide = true
    }

    if (mode==='long'){
      slideWidth = slider.clientWidth
    }
  })

  // onDestroy(()=>{
  //   clearInterval(timer)
  // })


  function scrollVertical(){
    
    // itsAlive = true
    if(checkVisible(slider) && isVisible !==true){
      isVisible = true
    }    
    
  }

  function changeVisibleSlide(){    
    if(isVisible){
      if (!isSendStat){
         fetch(`https://setka.media/external/block-view?bid=${bid}`)			
         isSendStat = true;
      }
      count = Math.trunc(windowWidth / (slideWidth + 16)) //целая часть видимых 
      ostatok = windowWidth % (slideWidth + 16)      // невидиммые 
      
      for (let index = 0; index < count; index++) {
        if(index < tiserList.length){
          tiserList[index].isVisible = true;
        }
        
      }

    }
  }

  function changeSlide(data){
    const id = data.detail.id
    const index = tiserList.findIndex(item=>{
      return item.id===id
    })
    
    if(spareTeasers.length){
      const newTeaser = spareTeasers.pop()
      newTeaser.isVisible = true

      tiserList[index] = newTeaser
    }
  }

  $: isVisible, changeVisibleSlide()

</script>

<!-- <svelte:window on:scroll={scrollVertical} on:mousemove={()=>{itsAlive=true}}/> -->
<svelte:window on:scroll={scrollVertical}/>
<div class="classic-slider">
  {#if signature_show==true}
    <HeaderComponent/>
  {/if}
  <div class="classic-slider__interface">

    {#if isLeftSide==false && !isMobile}
    <div class="classic-slider__arrow classic-slider__arrow_left" on:click={scrollLeft}>
      <svg width="25" height="25">
        <polygon points="0,12 25,25 25,0"
          fill="#888" stroke="#666" stroke-width="1"
        />
      </svg>
    </div>
    {/if}

    {#if isRightSide==false && !isMobile}
    <div class="classic-slider__arrow classic-slider__arrow_right" on:click={scrollRight}>
      <svg width="25" height="25">
        <polygon points="0,25 25,12 0,0"
          fill="#888" stroke="#666" stroke-width="1"
        />
      </svg>
    </div>  
    {/if}    
    <div class={type} bind:this={slider} on:scroll={checkScroll}>
      {#each tiserList as slide }
      <div class="slider__item">
        <ClassicSlide mode={mode} slide={slide}  size={{slideWidth, slideHeight}} bid={bid} on:changeSlide={changeSlide}/>
      </div>  
      {/each}    
    </div>  
  </div>
  
  
    
  
</div>

<style>
  .classic-slider{
	  display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  .classic-slider__interface{
    width: 100%;
    position: relative;
  }
  .classic-slider__arrow{
    position:absolute;
    top:calc(50% - 40px);
    background: rgba(255, 255, 255, .4);
    border-radius: 10px;
    height:70px;
    width:50px;
    display:flex;
    align-items:center;
    z-index: 10;
    opacity: 1;
    justify-content: center;
    
  }
  .classic-slider__arrow_right{
    right: 15px;    
  }
  .classic-slider__arrow_left{    
    left: 15px;
  }
  .slider, .slider_alternative{
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    position: relative;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    cursor: pointer;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .slider::-webkit-scrollbar, .slider_alternative::-webkit-scrollbar {
  display: none;
}

.slider_alternative .slider__item{
  border-radius: 5px;
  border: 1px solid rgba(39, 38, 38, 0.1);
}

</style>
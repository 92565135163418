<div class="setka-header">
	<img src="https://setka.media/images/favicon/logo.png">
	<span>Рекомендации</span>
	<a class="setka-header__link" href="https://setka.media" target="_blank" rel="noopener noreferrer">setka.media</a>
</div>
<style>
  .setka-header {
	display: flex;
	justify-content: start;
	align-items: center;
	color:rgba(39, 38, 38, 0.7);
	font-size: .7rem;
	padding-bottom: 5px;
  }
  .setka-header img {
  	width: 10px;
  	height: 15px;
  	margin-right: 4px;
  }
  .setka-header__link {
	padding-left: 5px;
	text-decoration: underline;
	font-weight: bold;
	color:rgba(39, 38, 38, 0.7) !important;
	font-size: .7rem;  
  }
</style>